/* When the user scrolls down, hide the navbar. When the user scrolls up, show the navbar */
var prevScrollpos = window.pageYOffset;
window.onscroll = () => {
  var currentScrollPos = window.pageYOffset;
  if (prevScrollpos > currentScrollPos) {
    document.getElementById("id-navigation-wrapper").style.top = "0";
  } else {
    document.getElementById("id-navigation-wrapper").style.top = "-90px";
  }
  prevScrollpos = currentScrollPos;
}  

const dots = document.querySelectorAll('.cardWrapper__dots');
const circle1 = document.querySelectorAll('.circle-1');
const circle2 = document.querySelectorAll('.circle-2');
const circle3 = document.querySelectorAll('.circle-3');
const circle4 = document.querySelectorAll('.circle-4');

const stroke = document.querySelector('.stroke');

const about = document.getElementById('about');

function animateDots () {
  //Initialize Scrollmagic
  const controller = new ScrollMagic.Controller ();
  //Define Timelines
  const tl1 = new TimelineLite();
  const tl2 = new TimelineLite();
  //Add Animation to Timeline
  tl1
  .from(circle1[0], .3, {opacity: 0}) 
  .from(circle2[0], .3, {delay: 0.08, opacity: 0}) 
  .from(circle3[0], .3, {delay: 0.08, opacity: 0}) 
  .from(circle4[0], .3, {delay: 0.08, opacity: 0}) 

  const scene1 = new ScrollMagic.Scene({
    triggerElement: dots[0],
    triggerHook: 'onEnter',
    offset: 100,
  })
    .reverse(false)
    .setTween(tl1)
    .addTo(controller)

  //Second Animation Dots
  tl2
  .from(circle1[1], .3, {opacity: 0}) 
  .from(circle2[1], .3, {delay: 0.08, opacity: 0}) 
  .from(circle3[1], .3, {delay: 0.08, opacity: 0}) 
  .from(circle4[1], .3, {delay: 0.08, opacity: 0}) 


  const scene2 = new ScrollMagic.Scene({
    triggerElement: dots[1],
    triggerHook: 'onEnter',
    offset: 100,
  })
    .reverse(false)
    .setTween(tl2)
    .addTo(controller)
}

//Animation Reveal Start
const aboutImg = document.querySelector('.about__wrapper--left img');
const aboutTextbox = document.querySelector('.about__wrapper--right');
const aboutOverlay = document.querySelector('.about__wrapper__overlay');

function animateImg() {
  const controller = new ScrollMagic.Controller()
  const tl = new TimelineLite(); 
  tl
  .from(aboutTextbox, 1.1, {opacity: 0, top: '50px'})
  .fromTo(aboutOverlay, 1.1, {width: '0%', height: aboutImg.style.height, right: 0}, {width: '100%'},0.5)
  .to(aboutImg, 1.1, {opacity: 1})
  .to(aboutOverlay, 1, {width: '0%', right: 'auto', left: 0}, 1.5)


  const scene = new ScrollMagic.Scene({
    triggerElement: about,
    triggerHook: 'onEnter',
    offset: 100
  })
  .reverse(false)
  .setTween(tl)
  .addTo(controller)
  //.addIndicators()
}


//END
  


function animateStrokes () {
  //Initialize Scrollmagic
  const controller = new ScrollMagic.Controller ();
  //Define Timelines
  const tl1 = new TimelineLite();
  //Add Animation to Timeline
  tl1
  .from(stroke, 1.1, {ease: Power3.easeInOut, width: 0}) 

  const scene1 = new ScrollMagic.Scene({
    triggerElement: stroke,
    triggerHook: 'onEnter',
    offset: 100,
  })
    .reverse(false)
    .setTween(tl1)
    .addTo(controller)
}

//Check if Animation is required
if(dots.length > 0) {
  animateDots()
}

if(about) {
  animateImg()
}

if (stroke) {
  animateStrokes()
}